<template>
  <div class="moment">
     <div class="logo">
      <div class="banner-font">
        <div class="font-english">LIVE THE WONDERFUL MOMENTS AND LEAVE THE SPORTS BEAUTIFUL</div>
        <div class="font-chinese">抓住精彩瞬间  留下运动美好</div>
        <!-- <div class="font-behavior">
            <div class="font-understand">了解商家版</div>
            <div class="font-join">加入商家版</div>
        </div> -->
        
      </div>
      <div class="font-download">
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-center"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/sportQrcode.png')"
                  ></el-image>
                </div>
                <div class="font-download-apple"></div>
              </el-tooltip>
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-bottom"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/sportQrcode.png')"
                  ></el-image>
                </div>
                <div class="font-download-android"></div>
              </el-tooltip>
            </div>
      <!-- <div class="mode">

      </div> -->
    </div>
    <div class="moment-imgTop">
        <div class="moment-imgTop-left">
            <img src="../assets/img/6H5A0271.jpg" alt=""/>
           <div class="moment-behavior">
            <span class="iconfont icon-dianzan1 thumbs"></span>
            <span class="thumbsNumber">1.1K</span>
           </div>
        </div>
        <div class="moment-imgTop-center">
             <img src="../assets/img/6H5A3470.jpg" alt=""/>
            <div class="moment-behavior">
            <span class="iconfont icon-dianzan1 thumbs"></span>
            <span class="thumbsNumber">800</span>
           </div>
        </div>
        <div class="moment-imgTop-right">
             <img src="../assets/img/6H5A41581.jpg" alt=""/>
            <div class="moment-behavior">
            <span class="iconfont icon-dianzan1 thumbs"></span>
            <span class="thumbsNumber">22K</span>
           </div>
        </div>
       
    </div>
     <div class="moment-imgBottom">
            <div class="moment-imgBottom-content" v-for="(item,index) in imgBottomList" :key="index">
             <img :src="item.url" alt=""/>   
             <div class="moment-behavior">
            <span class="iconfont icon-dianzan1 thumbs"></span>
            <span class="thumbsNumber">{{item.num}}</span>
           </div>
            </div>
    </div>
    <div class="download">
      <div class="download-content">
        <div class="download-content-left">
          <div class="download-content-left-info">
            <div class="iconlogo">
              <el-image
                class="iconlogoimg"
                :src="require('../assets/img/logo_white.png')"
              ></el-image>
              <span class="iconlogotext">运动官</span>
            </div>
            <div class="download-content-left-introduce">
              <span>运动生活&nbsp;&nbsp;&nbsp;快乐有我<br /></span>
              <span>科学运动引领者<br /></span>
            </div>
            <div class="download-content-left-mode">
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-center"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/sportQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-apple"></div>
              </el-tooltip>
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-bottom"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/sportQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-android"></div>
              </el-tooltip>
            </div>
            <div class="iconlogo" style="margin-top: 0.8rem">
              <el-image
                class="iconlogoimg"
                :src="require('../assets/img/logo_black.png')"
              ></el-image>
              <span class="iconlogotext">运动官商家版</span>
            </div>
            <div class="download-content-left-introduce">
              <span>垂直体育分销系统<br /></span>
              <span>科学运动引领者<br /></span>
            </div>
            <div class="download-content-left-mode">
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-center"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/businessQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-apple"></div>
              </el-tooltip>
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-bottom"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/businessQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-android"></div>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="download-content-right">
          <div class="download-content-right-mode"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data(){
        return{
            imgBottomList:[
                {
                    url:require('../assets/img/mine-content-img-1.jpg'),
                    num:'12K'
                },
                {
                    url:require('../assets/img/6H5A5391.jpg'),
                    num:'1.5K'
                },{
                    url:require('../assets/img/6H5A5663.jpg'),
                    num:'700'
                },
                {
                    url:require('../assets/img/6H5A5601.jpg'),
                    num:'11K'
                },
                {
                    url:require('../assets/img/6H5A5562.jpg'),
                    num:'500'
                },
                {
                    url:require('../assets/img/6H5A6556.jpg'),
                    num:'5K'
                },
                {
                    url:require('../assets/img/6H5A6956.jpg'),
                    num:'900'
                },
                {
                    url:require('../assets/img/6H5A6969.jpg'),
                    num:'600'
                },
                {
                    url:require('../assets/img/6H5A9327.jpg'),
                    num:'18K'
                },
            ]
        }
    },
     created(){
        document.documentElement.scrollTop = 0
    }
}
</script>

<style lang="less" scoped>
@keyframes fadenum{
   0%{opacity: 0;}
   50%{opacity: 0.8;}
   100%{opacity: 1;}
}
.moment {
  width: 100%;
  .logo {
    width: 100%;
    height: 5.5rem;
    // height: 100%;
    position: relative;
    text-align: center;
    // background-size:contain;
    background: url("../assets/img/huaban.jpg") no-repeat ;
    background-size: 100% 100%;
    .mode{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #111;
    opacity: 0.4;
    z-index: 1;
  }
  .font-download{
      position: absolute;
      width: 2rem;
      // height: 0.3rem;
      // background-color: red;
      bottom:0.4rem;
      right: 0.6rem;
      z-index: 2;
      // bottom: 0.2rem;
      display: flex;
            justify-content: space-between;
            .font-download-apple {
              width: 0.9rem;
              height: 0.25rem;
              background: url("../assets/img/u912.png") no-repeat center;
              background-size: 80% 80%;
              border: 1px solid #fff;
              border-radius: 5px;
              background-color: #111;
              cursor: pointer;
            }
            .font-download-android {
              width: 0.9rem;
              height: 0.25rem;
              background: url("../assets/img/u913.png") no-repeat center;
              background-size: 80% 80%;
              border: 1px solid #fff;
              border-radius: 5px;
              background-color: #111;
              cursor: pointer;
            }
    }
  }
  
  .banner-font {
    position: absolute;
    top: 40%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    z-index: 2;
    .font-english {
      width: max-content;
      font-weight: 600;
      font-size: 0.2rem;
      animation: fadenum 4s 1;
      -webkit-animation: fadenum 4s 1;
      -moz-animation: fadenum 4s 1;
    }
    .font-chinese {
      margin-top: 0.12rem;
      font-size: 0.12rem;
      animation: fadenum 4s 1;
      -webkit-animation: fadenum 4s 1;
      -moz-animation: fadenum 4s 1;
    }
    .font-behavior{
      width: 2.5rem;
      height: 0.26rem;
      // background-color: red;
      margin: 1rem auto 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 0.26rem;
      .font-understand{
        height: 100%;
        padding:0 0.25rem;
        font-size: 0.08rem;
        background-color: #111;
        border: 1px solid #fff; 
        border-radius: 10px;
        cursor: pointer;
      }
      .font-join{
        height: 100%;
        padding:0 0.25rem;
        font-size: 0.08rem;
        cursor: pointer;
         background-color: rgba(0,0,0,0.1);
        border: 1px solid #fff; 
        border-radius: 10px;
      }
      .font-join:hover{
       background-color: #111;
       
      }
    }
    
  }
  .moment-imgTop{
    width: 100%;
    height: 2.5rem;
    display: flex;
    justify-content: space-around;
    .moment-imgTop-left{
        width: 1.5rem;
        // height: 100%;
        // background: url("../assets/img/6H5A0271.jpg") no-repeat center;
        // background-size: 100% 100%;
        margin: 0.1rem;
        position: relative;
        img{
            display: block;
            width: 100%;
            height: 100%;
        }
        .moment-behavior{
            position: absolute;
            width: 0.2rem;
            right: 0.1rem;
            bottom: 0.1rem;
            text-align: center;
            .thumbs{
            display: block;
            font-size: 0.2rem;
            color:rgba(132,0 ,255,255) !important;
        }
        .thumbsNumber{
            // display: block;
            // margin-top: 10px;
            font-size: 0.07rem !important;
            background-color: #fff;
            color:rgba(132,0 ,255,255) !important;
        }
        }
        
    }
    .moment-imgTop-center{
        flex: 1;
         margin: 0.1rem 0;
         position: relative;
        // background: url("../assets/img/6H5A3470.jpg") no-repeat center;
        // background-size: 100% 100%;
         img{
            display: block;
            width: 100%;
            height: 100%;
        }
        .moment-behavior{
            position: absolute;
            width: 0.2rem;
            right: 0.1rem;
            bottom: 0.1rem;
            text-align: center;
            .thumbs{
            display: block;
            font-size: 0.2rem;
            color:rgba(132,0 ,255,255) !important;
        }
        .thumbsNumber{
            // display: block;
            // margin-top: 10px;
            font-size: 0.07rem !important;
            background-color: #fff;
            color:rgba(132,0 ,255,255) !important;
        }
        }


    }
    .moment-imgTop-right{
         flex: 1;
         margin: 0.1rem ;
         position: relative;
        // background: url("../assets/img/6H5A41581.jpg") no-repeat center;
        // background-size: 100% 100%;
         img{
            display: block;
            width: 100%;
            height: 100%;
        }
        .moment-behavior{
            position: absolute;
            width: 0.2rem;
            right: 0.1rem;
            bottom: 0.1rem;
            text-align: center;
            .thumbs{
            display: block;
            font-size: 0.2rem;
            color:rgba(132,0 ,255,255) !important;
        }
        .thumbsNumber{
            // display: block;
            // margin-top: 10px;
            font-size: 0.07rem !important;
            background-color: #fff;
            color:rgba(132,0 ,255,255) !important;
        }
        }


    }
  }
  .moment-imgBottom{
    // width: 100%;
    margin: 0 0.1rem;
    // height: 2.5rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
     flex-direction: row;
    
    .moment-imgBottom-content{
        position: relative;
        //  flex: 1;
          width: 3.2rem;
          margin-bottom: 0.1rem;
        //  margin: 0.1rem;
        img{
            display: block;
            width: 100%;
            height: 100%;
        }
        .moment-behavior{
            position: absolute;
            width: 0.2rem;
            right: 0.1rem;
            bottom: 0.1rem;
            text-align: center;
            .thumbs{
            display: block;
            font-size: 0.2rem;
            color:rgba(132,0 ,255,255) !important;
        }
        .thumbsNumber{
            font-size: 0.07rem !important;
            background-color: #fff;
            color:rgba(132,0 ,255,255) !important;
        }
        }
    }
        
  }
  .download {
    width: 100%;  
    // height: 4.5rem;
    background-color: rgba(34, 34, 34, 1);
    .download-content {
      display: flex;
      // height: 100%;
      .download-content-left {
        width: 3rem;
        // height: 100%;
        margin: 0.5rem 0;
        display: flex;
        align-items: center;
        background-color: rgba(34, 34, 34, 1);
        .download-content-left-info {
          width: 2rem;
          // height: 3.5rem;
          margin: 0 auto;
          background-color: rgba(34, 34, 34, 1);
          color: #fff;
          .iconlogo {
            display: flex;
            align-items: center;
            .iconlogoimg {
              width: 0.15rem;
              height: 0.15rem;
            }
            .iconlogotext {
              font-size: 0.11rem;
              color: #fff;
              margin-left: 0.04rem;
            }
          }
          .download-content-left-introduce {
            margin-top: 0.15rem;
            font-size: 0.07rem;
            line-height: 28px;
          }
          .download-content-left-mode {
            display: flex;
            justify-content: space-between;
            margin-top: 0.4rem;
            .download-content-left-mode-apple {
              width: 0.9rem;
              height: 0.25rem;
              background: url("../assets/img/u912.png") no-repeat center;
              background-size: 80% 80%;
              border: 1px solid #fff;
              border-radius: 5px;
              background-color: #111;
              cursor: pointer;
            }
            .download-content-left-mode-android {
              width: 0.9rem;
              height: 0.25rem;
              background: url("../assets/img/u913.png") no-repeat center;
              background-size: 80% 80%;
              border: 1px solid #fff;
              border-radius: 5px;
              background-color: #111;
              cursor: pointer;
            }
          }
        }
      }
      .download-content-right {
        position: relative;
        flex: 1;
        background: url("../assets/img/beiying.jpg") no-repeat;
        background-size: 100% 100%;
        .download-content-right-mode {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #111;
          opacity: 0.5;
        }
      }
    }
  }
   
}
</style>